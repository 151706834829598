import React from 'react'
import { useHistory } from 'react-router-dom'
import LazyLoad from 'react-lazyload'

import { IWork } from 'constants/images'
import useMediaQuery from 'utils/useMediaQuery'

import {
    Title,
    GalleryWrapper,
    ImgWrapper,
    ImgButton,
    Img,
} from './workGallery.styles'

interface IWorkGalleryProps {
    work: IWork
}

function WorkGallery({
    work: { id: workId, title, images },
}: IWorkGalleryProps) {
    const history = useHistory()

    const isTablet = useMediaQuery('(min-width: 768px)')
    const isPhablet = useMediaQuery('(min-width: 480px)')
    const isPhone = useMediaQuery('(min-width: 376px)')

    const getMaxImgsByMediaQuery = () => {
        if (isTablet) return 12
        if (isPhablet) return 6
        if (isPhone) return 5
        return 12
    }

    return (
        <>
            <Title>{title}</Title>
            <GalleryWrapper id={`${title}-gallery-wrapper`}>
                {images?.map(({ alt, source, height }, index: number) => {
                    if (index < getMaxImgsByMediaQuery()) {
                        return (
                            <LazyLoad
                                height={height}
                                offset={800}
                                once={true}
                                key={source?.regular}
                            >
                                <ImgWrapper>
                                    <ImgButton
                                        onClick={() => {
                                            history.push(
                                                `/works/${workId}/${index}`,
                                            )
                                        }}
                                    >
                                        <Img
                                            width="100%"
                                            height="100%"
                                            src={source.thumbnail}
                                            alt={alt}
                                            loading="lazy"
                                            index={index}
                                        />
                                    </ImgButton>
                                </ImgWrapper>
                            </LazyLoad>
                        )
                    }
                    return null
                })}
            </GalleryWrapper>
        </>
    )
}

export default WorkGallery
