import React from 'react'

import { IconInstagram } from '../../components/icons'

import {
    MainContact,
    Title,
    ParagraphStyled,
    LinkStyled,
    Rights,
} from './contact.styles'

function Contact() {
    return (
        <MainContact>
            <Title>Studio</Title>
            <ParagraphStyled>contact@alejandroguijarro.com</ParagraphStyled>

            <LinkStyled
                href="https://www.instagram.com/alejandroguijarrostudio/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Go to Alejandro Guijarro Instagram profile"
            >
                <IconInstagram />
                <p>Instagram</p>
            </LinkStyled>

            <Rights>
                <p>
                    &copy; {new Date().getFullYear()} Alejandro Guijarro All
                    Rights Reserved.
                </p>
            </Rights>
        </MainContact>
    )
}

export default Contact
