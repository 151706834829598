import styled from 'styled-components/macro'

import { theme, media } from 'styles'

const { colors } = theme

export const FooterContainer = styled.section`
    > div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    display: flex;
    flex-direction: column;
    min-width: 100%;
    width: 100%;
    min-height: 50px;
    height: 100px;
    color: ${colors.grey};
    background-color: white;
    font-size: 0.6rem;
    text-align: center;
    z-index: ${theme.zIndexFooter};
    border-top: solid 0.5px ${colors.lightestGrey};
    margin-top: 50px;
    padding-top: 20px;
    ${media.desktop`
      padding: 1rem 0 5rem 0;
    `};
    ${media.tablet`
      padding: 1rem 0 4rem 0;
    `};
    ${media.phablet`
      padding: 1rem 0 4rem 0;
    `};
    ${media.tiny`
      padding: 1rem 0 4rem 0;
    `};
`

export const FooterSocialIcons = styled.div`
    grid-area: instagram;
    justify-self: start;
    ${media.tiny`
      justify-self: center;
    `};

    a {
        padding: 0 5px;
        width: 1.2rem;
        color: ${colors.lightGrey};
        &:hover {
            color: ${colors.black};
        }
    }
`

export const FooterBackToTop = styled.div`
    grid-area: backToTop;

    ${media.phablet`
      justify-self: end;
    `};
    ${media.tiny`
      justify-self: start;
    `};

    button {
        color: ${colors.lightGrey};
        font-size: 0.6rem;
        background-color: transparent;
        transition: ${theme.transition};
        &:hover {
            color: ${colors.black};
        }
    }
`

export const FooterNav = styled.nav`
    ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        li {
            color: ${colors.lightGrey};
            padding: 0 5px;
            font-weight: 200;
            border-right: solid 1px ${colors.lightestGrey};
            &:last-child {
                border-right: none;
            }
            .active {
                font-weight: 900;
                color: ${colors.black};
            }
            a {
                &:hover {
                    color: ${colors.black};
                }
            }
        }
    }
`

export const RightsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;
    ${media.tablet`
      justify-content: center;
    `};
    ${media.tiny`
    `};
    p {
        color: ${colors.lightGrey};
        padding: 0;
        margin: 0;
        a {
            &:hover {
                color: ${colors.black};
            }
        }
    }
`
