import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import ScrollToTop from 'components/ScrollToTop/ScrollToTop'

import NavBar from 'components/HeaderNav/NavBar'
// import HeaderNav from 'components/HeaderNav/HeaderNav'
// import SuspenseSpinner from 'components/Spinner/SuspenseSpinner'

import Home from 'pages/Home/Home'
import Works from 'pages/Works/Works'
import WorkDetail from 'pages/WorkDetail/WorkDetail'
import Bio from 'pages/Bio/Bio'
import Contact from 'pages/Contact/Contact'
import NoMatch from 'pages/NoMatch/NoMatch'

// const Home = lazy(() => import('../../pages/Home/Home'))
// const Works = lazy(() => import('../../pages/Works/Works'))
// const WorkDetail = lazy(() => import('../../pages/WorkDetail/WorkDetail'))
// const Bio = lazy(() => import('../../pages/Bio/Bio'))
// const Contact = lazy(() => import('../../pages/Contact/Contact'))
// const NoMatch = lazy(() => import('../../pages/NoMatch/NoMatch'))

function Routes() {
    return (
        <>
            {/* <Suspense fallback={<SuspenseSpinner />}> */}
            <Router>
                {/* <HeaderNav /> */}
                <NavBar />
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/works" component={Works} />
                        <Route
                            exact
                            path="/works/:workId/:index"
                            component={WorkDetail}
                        />
                        <Route exact path="/bio" component={Bio} />
                        <Route exact path="/contact" component={Contact} />
                        <Route component={NoMatch} />
                    </Switch>
                </ScrollToTop>
            </Router>
            {/* </Suspense> */}
        </>
    )
}

export default Routes
