/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import styled from 'styled-components/macro'

import { theme } from 'styles'

const { colors } = theme

function BurguerButton(props: { clicked: boolean; handleClick: () => void }) {
    return (
        <Burguer>
            <div
                onClick={props.handleClick}
                className={`icon nav-icon-8 ${props.clicked ? 'open' : ''}`}
            >
                <span></span>
                <span></span>
                <span></span>
            </div>
        </Burguer>
    )
}

export default BurguerButton

const Burguer = styled.div`
    /* nav-icon-8 */
    .nav-icon-8 {
        width: 30px;
        height: 25px;
        margin: 10px 10px;
        position: relative;
        cursor: pointer;
        display: inline-block;
    }
    .nav-icon-8 span {
        background-color: ${colors.grey};
        position: absolute;
        border-radius: 2px;
        transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
        width: 100%;
        height: 4px;
    }
    .nav-icon-8 span:nth-child(1) {
        top: 0px;
        left: 0px;
    }
    .nav-icon-8 span:nth-child(2) {
        top: 10px;
        left: 0px;
    }
    .nav-icon-8 span:nth-child(3) {
        bottom: 0px;
        left: 0px;
    }
    .nav-icon-8:not(.open):hover span:nth-child(1) {
        transform: scaleX(0.8);
    }
    .nav-icon-8:not(.open):hover span:nth-child(2) {
        transform: scaleX(0.5);
    }
    .nav-icon-8:not(.open):hover span:nth-child(3) {
        transform: scaleX(0.8);
    }
    .nav-icon-8.open span:nth-child(1) {
        top: 13px;
    }
    .nav-icon-8.open span:nth-child(2) {
        top: 13px;
    }
    .nav-icon-8.open span:nth-child(3) {
        top: 13px;
    }
`
