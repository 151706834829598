import React from 'react'
import { NavLink } from 'react-router-dom'

// import { smoothScroll } from 'components/SmoothScroll/SmoothScroll'

import { IconInstagram } from '../icons'

import {
    FooterContainer,
    FooterSocialIcons,
    // FooterBackToTop,
    FooterNav,
    RightsWrapper,
} from './footer.styles'

// const scrollToTop = () => {
//     smoothScroll.scrollTo('root')
// }

function Footer() {
    return (
        <FooterContainer>
            <div>
                <FooterSocialIcons>
                    <a
                        href="https://www.instagram.com/alejandroguijarrostudio/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Go to Alejandro Guijarro Instagram profile"
                    >
                        <IconInstagram />
                    </a>
                </FooterSocialIcons>
                {/* <FooterBackToTop>
                    <button onClick={() => scrollToTop()}>
                        Back to Top &uarr;
                    </button>
                </FooterBackToTop> */}
                <FooterNav>
                    <ul>
                        <li>
                            <NavLink
                                id="Home"
                                exact
                                to="/"
                                activeClassName="active"
                            >
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                id="Works"
                                to="/works"
                                activeClassName="active"
                            >
                                Works
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                id="Bio"
                                to="/bio"
                                activeClassName="active"
                            >
                                Bio
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                id="Contact"
                                to="/contact"
                                activeClassName="active"
                            >
                                Contact
                            </NavLink>
                        </li>
                    </ul>
                </FooterNav>
            </div>
            <RightsWrapper>
                <p>
                    &copy; {new Date().getFullYear()} Alejandro Guijarro All
                    Rights Reserved.
                </p>
            </RightsWrapper>
        </FooterContainer>
    )
}

export default Footer
