import styled from 'styled-components/macro'

import { theme, media } from 'styles'

const { colors } = theme

export const MainContact = styled.main`
    max-height: calc(100vh - ${theme.navHeight});
    text-align: center;
    padding-top: 2rem;
    color: ${colors.lightGrey};
    p {
        padding: 0;
    }
    ${media.phablet`
        padding: 1rem 0.5rem 0 0.5rem;
    `};
`

export const Title = styled.p`
    color: ${colors.black};
    font-weight: bold;
`

export const ParagraphStyled = styled.p`
    ${media.phablet`
        padding-top: 1%;
    `};
`

export const LinkStyled = styled.a`
    padding: 0.5rem 0;
    margin-top: 1rem;
    transition: ${theme.transition};
    ${media.phablet`
        padding-bottom: 2%;
    `};
    svg {
        transition: ${theme.transition};
        color: ${colors.lightGrey};
        width: 0.8rem;
    }
    p {
        transition: ${theme.transition};
        color: ${colors.lightGrey};
    }
    &:hover {
        p {
            color: ${colors.black};
        }
        svg {
            color: ${colors.black};
        }
    }
`

export const Rights = styled.div`
    grid-area: rights;
    grid-column: 1 / span 3;
    grid-row: 2;
    padding-top: 6rem;
    ${media.desktop`
        justify-self: end;
    `};
    ${media.tablet`
        justify-self: center;
    `};
    ${media.phablet`
        padding: 1rem 1rem 0 1rem;
        /* font-size: 0.5rem; */
    `};
    p {
        color: ${colors.lightGrey};
        padding: 0 5px;
        a {
            font-size: 0.7rem;
            color: ${colors.lightGrey};
            &:hover {
                color: ${colors.black};
            }
        }
    }
`
