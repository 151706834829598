import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { theme, media } from 'styles'

const { colors } = theme

export const Transition = styled.div`
    .active {
        visibility: visible;
        transition: all 300ms ease-in;
    }
    .hidden {
        visibility: hidden;
        display: none;
        transition: all 300ms ease-out;
        transform: translate(0, -100%);
    }
`

export const NavContainer = styled.nav`
    position: fixed;
    padding: 1rem 0.4rem;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: ${theme.zIndexNav};
    background-color: white;
    /* min-height: calc(${theme.navHeight} + 30px);
    height: calc(${theme.navHeight} + 30px); */
    ${() =>
        document.body.getBoundingClientRect().top !== 0 &&
        'box-shadow: 0 15px 15px -2px rgba(0, 0, 0, 0.2)'};
    @media (min-width: 768px) {
        flex-direction: column;
        justify-content: center;
    }
    a {
        color: ${colors.grey};
    }
    .links {
        z-index: ${theme.zIndexNav};
        display: none;
        top: -700px;
        left: -2000px;
        right: 0;
        margin-top: 30%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        transition: all 0.5s ease;
        a {
            font-size: 2rem;
            display: block;
        }
        @media (min-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
            position: initial;
            margin: 0.5rem 0 0 0;
            a {
                font-size: 0.7rem;
                color: white;
                display: inline;
                color: ${colors.grey};
                margin: 0 5px;
                &:hover {
                    color: ${colors.lightGrey};
                }
            }
            a.active {
                font-weight: 900;
                margin: 0 5px;
                color: ${colors.grey};
            }
        }
    }
    .links.active {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 30%;
        left: 0;
        right: 0;
        text-align: center;
        transition: all 0.5s ease;
        a {
            font-size: 1rem;
            font-weight: 500;
            margin-top: 1rem;
            color: ${colors.grey};
            &:hover {
                color: ${colors.lightGrey};
            }
        }
        a.active {
            font-weight: 900;
            margin-top: 1rem;
            color: ${colors.grey};
        }
        @media (min-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
            position: initial;
            margin: 0.5rem 0 0 0;
            a {
                font-size: 0.7rem;
                color: white;
                display: inline;
                color: ${colors.grey};
                margin: 0 5px;
                &:hover {
                    color: ${colors.lightGrey};
                }
            }
            a.active {
                font-weight: 900;
                margin: 0 5px;
                color: ${colors.grey};
            }
        }
    }
    .burguer {
        z-index: ${theme.zIndexNav};
        @media (min-width: 768px) {
            display: none;
        }
    }
`

export const NavTitle = styled(NavLink)`
    flex: auto;
    z-index: ${theme.zIndexNav};
    color: ${colors.grey};
    letter-spacing: 0.2rem;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 900;
    margin-bottom: 0.5rem;
    transition: ${theme.transition};
    &:hover {
        color: ${colors.lightGrey};
    }
    ${media.tablet`
    `};
    ${media.phablet`
    `};
    ${media.phone`
    `};
    ${media.tiny`
    `};
`

export const BgDiv = styled.div`
    /* background-color: #222; */
    background-color: ${colors.white};
    position: fixed;
    top: -1000px;
    left: -1000px;
    width: 100%;
    height: 100%;
    z-index: ${theme.zIndexNavBg};
    transition: all 0.6s ease;

    &.active {
        border-radius: 0 0 30px 30px;
        top: 0;
        left: 0;
        width: 100%;
        height: 80%;
    }

    @media (min-width: 768px) {
        display: none;
    }
`
