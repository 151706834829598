/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { useGlobalStore } from 'app/store/global.store'

import BurguerButton from './BurguerButton'

import { Transition, NavContainer, NavTitle, BgDiv } from './navBar.styles'

function NavBar() {
    const [clicked, setClicked] = useState(false)

    const { worksModalIsOpen } = useGlobalStore(
        state => ({ worksModalIsOpen: state.worksModalIsOpen }),
        shallow,
    )

    const match = useRouteMatch<any>('/works/:workId/:index')
    const isWorkDetailPage = !!match?.params?.workId

    const [showNavBar, setShowNavBar] = useState<boolean>(true)
    const [scrollPos, setScrollPos] = useState<number>(0)
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(worksModalIsOpen)

    const handleScroll = useCallback(() => {
        setScrollPos(document.body.getBoundingClientRect().top)
        setShowNavBar(document.body.getBoundingClientRect().top > scrollPos)
    }, [document, scrollPos])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [window, handleScroll])

    useEffect(() => {
        setModalIsOpen(worksModalIsOpen)
    }, [worksModalIsOpen])

    if (isWorkDetailPage) return null

    const handleClick = () => {
        setClicked(prevClicked => !prevClicked)
    }
    return (
        <>
            <Transition>
                <NavContainer
                    className={showNavBar && !modalIsOpen ? 'active' : 'hidden'}
                >
                    <NavTitle exact to="/" activeClassName="active">
                        ALEJANDRO GUIJARRO
                    </NavTitle>
                    <div className={`links ${clicked ? 'active' : ''}`}>
                        <NavLink
                            to="/works"
                            activeClassName="active"
                            onClick={handleClick}
                        >
                            Works
                        </NavLink>
                        <NavLink
                            to="/bio"
                            activeClassName="active"
                            onClick={handleClick}
                        >
                            Bio
                        </NavLink>
                        <NavLink
                            to="/contact"
                            activeClassName="active"
                            onClick={handleClick}
                        >
                            Contact
                        </NavLink>
                    </div>
                    <div className="burguer">
                        <BurguerButton
                            clicked={clicked}
                            handleClick={handleClick}
                        />
                    </div>
                    <BgDiv
                        className={`initial ${clicked ? ' active' : ''}`}
                    ></BgDiv>
                </NavContainer>
            </Transition>
        </>
    )
}

export default NavBar
