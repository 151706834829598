const theme = {
    colors: {
        green: '#1DB954',
        offGreen: '#1ed760',
        blue: '#509bf5',
        navWhite: '#fafafa',
        black: '#181818',
        white: '#fafafa',
        lightestGrey: '#ddd',
        lightGrey: '#9B9B9B',
        grey: '#404040',
        darkGrey: '#282828',
    },

    fonts: {
        primary: '"Arial", sans-serif',
        secondary:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },

    fontSizes: {
        base: `14px`,
        xs: `12px`,
        sm: `14px`,
        md: `20px`,
        lg: `24px`,
        xl: `28px`,
        xxl: `32px`,
    },

    spacing: {
        base: `20px`,
        xs: `5px`,
        sm: `10px`,
        md: `30px`,
        lg: `50px`,
        xl: `100px`,
    },

    easing: {
        easeInCubic: `cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
        easeOutCubic: `cubic-bezier(0.215, 0.61, 0.355, 1)`,
        easeInOutCubic: `cubic-bezier(0.215, 0.61, 0.355, 1)`,
        easeInExpo: `cubic-bezier(0.95, 0.05, 0.795, 0.035)`,
        easeOutExpo: `cubic-bezier(0.19, 1, 0.22, 1)`,
        easeInOutExpo: `cubic-bezier(0.19, 1, 0.22, 1)`,
        easeInBack: `cubic-bezier(0.6, -0.28, 0.735, 0.045)`,
        easeOutBack: `cubic-bezier(0.175, 0.885, 0.32, 1.275)`,
        easeInOutBack: `cubic-bezier(0.68, -0.55, 0.265, 1.55)`,
    },

    transition: `all 0.25s cubic-bezier(0.3, 0, 0.4, 1);`,

    navWidth: '100px',
    navHeight: 'calc(50px + 1.5rem)',

    zIndexNav: 2000,
    zIndexNavBg: 999,
    zIndexFooter: 100,
    zIndexWorkDetail: 1,
}

export default theme
